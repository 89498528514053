<template>
  <div class="fields-type-event-setting">
    <div class="pb-3">
      <v-row class="">
        <v-col cols="5">
          <div class="style-title-setting-global">
            <span
              class="bold-700 mr-2 title-hover cursor-pointer"
              @click="handelCancel($event)"
            >
              {{ $i18n.locale === 'fr' ? "Type d'événement" : 'Event type' }}
              <v-icon class="color-crm">mdi-chevron-right</v-icon></span
            >
            <span
              class="color-crm font-sz-18 bold-500 font-text text-capitalize text-overflow-setting"
              >{{
                getOneTypeEvent && getOneTypeEvent.designation
                  ? getOneTypeEvent.designation
                  : getOneTypeEvent
              }}</span
            >
          </div>
          <div class="message mt-2">
            <div v-if="hasError" class="error-msg">
              <ul v-if="Array.isArray(errorMessages)">
                <li v-for="(e, index) in errorMessages" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorMessages }}</span>
            </div>
          </div>
        </v-col>
        <v-col>
          <v-progress-circular
            v-if="
              (getTypeEventLoading || getCustomFieldsLoading) &&
                !initLoading &&
                !loading
            "
            class="float-right mt-1"
            indeterminate
            color="#5C2DD3"
          ></v-progress-circular>
        </v-col>
        <v-col>
          <v-btn
            class="btn-filter float-right"
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleClickBtnAddFields"
          >
            {{
              $i18n.locale == 'fr'
                ? 'Affecter champs personnalisés'
                : 'Assign custom fields'
            }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="content mt-2" v-else>
      <div
        v-if="
          getOneTypeEvent &&
            (!getOneTypeEvent.fields || !getOneTypeEvent.fields.length)
        "
        class="div-aucun-list"
      >
        <div class="titre">
          {{
            $i18n.locale == 'fr'
              ? "Aucun champ disponible pour ce type d'événement"
              : 'No fields available for this type of event'
          }}
        </div>
      </div>
      <div class="card-scene" ref="cardSceneCategory" v-else>
        <Container
          :drop-placeholder="dropPlaceholderOptions"
          :lock-axis="'y'"
          @drop="onColumnDrop"
          drag-handle-selector=".column-drag-container "
        >
          <Draggable v-for="champ in getOneTypeEvent.fields" :key="champ.id">
            <div class=" ">
              <div class="card-column-header">
                <div class="input-title-sous-type">
                  <v-row>
                    <v-col cols="5">
                      <v-text-field
                        class="style-color-text-diabled"
                        disabled
                        :label="$t('designation')"
                        dense
                        @change="updateNameChamp($event, champ)"
                        :title="
                          $i18n.locale === 'fr'
                            ? 'Modifier le nom de champ'
                            : 'Modify field name'
                        "
                        :placeholder="
                          $i18n.locale === 'fr' ? 'Nom de champ' : 'Field name'
                        "
                        :value="champ.name"
                        :persistent-placeholder="true"
                        outlined
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                      >
                        <template #prepend>
                          <v-icon
                            color="#5c2dd3"
                            class="cursor-move mr-2 ml-2 bold-700 color-crm font-sz-30 mb-1"
                            :class="{
                              'column-drag-container':
                                getOneTypeEvent &&
                                getOneTypeEvent.fields &&
                                getOneTypeEvent.fields.length > 1
                            }"
                            >mdi-drag-horizontal-variant</v-icon
                          >
                        </template>
                      </v-text-field></v-col
                    >
                    <v-col cols="6">
                      <v-switch
                        class="input-checkbox switch-bottom label-switch float-right mt-2"
                        v-model="champ.required"
                        @change="updaterequiredChamp($event, champ)"
                        color="#5C2DD3"
                        :label="
                          $i18n.locale === 'fr'
                            ? 'Champ obligatoire'
                            : 'Required field'
                        "
                        hide-details
                      >
                      </v-switch>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        color="#5c2dd3"
                        class="font-sz-14 bold-700 btn-deleted-user float-right"
                        @click="tryDeleteChamp(champ)"
                        :title="
                          $i18n.locale == 'fr'
                            ? 'Supprimer un champ'
                            : 'Delete field'
                        "
                        fab
                        small
                        icon
                      >
                        <v-icon>mdi-close-circle-outline</v-icon></v-btn
                      ></v-col
                    >
                  </v-row>
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
      </div>
    </div>

    <!-- Deleted champs -->
    <v-dialog v-model="deleteChampModal" max-width="700" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale == 'fr' ? 'Supprimer un champ' : 'Delete field' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('deleteChampModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="mb-3 color-black-light">
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'le champ :' : 'the field :'
              })
            }}
            <strong class="text-capitalize">
              {{ champToDelete ? champToDelete.name : '' }}
            </strong>
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTypeEventLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTypeEventError" class="error-msg">
              <ul v-if="Array.isArray(getTypeEventError)">
                <li v-for="(e, index) in getTypeEventError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTypeEventError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="deleteChamp"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('deleteChampModal')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Champ custom fields Modal -->
    <v-dialog
      v-model="addFieldsToTypeEvent"
      max-width="900"
      persistent
      scrollable
      :retain-focus="false"
      :eager="true"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale == 'fr'
                ? 'Affecter des champs personnalisés'
                : 'Assign custom fields'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addFieldsToTypeEvent')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="loader-content" v-if="getCustomFieldsLoading">
            <v-progress-circular
              indeterminate
              class="ml-3"
              color="#5C2DD3"
            ></v-progress-circular>
          </div>
          <v-form ref="addFieldsToTypeEvent" class="mt-2" v-else>
            <v-row class="mt-2">
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  multiple
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :placeholder="$t('searchMsg', { msg: $t('champ') })"
                  item-color="#5C2DD3"
                  v-model="listChamp"
                  :items="getcustomFields"
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Liste des champs'
                      : 'List of fields'
                  "
                  :item-text="nameWithLang"
                  :loading="getCustomFieldsLoading"
                  dense
                  item-value="name"
                  :no-data-text="$t('noDataOptionMsg', { option: $t('champ') })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (v && v.length > 0) ||
                      $t('msgObligMsg', { msg: $t('champ') })
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="listChamp && listChamp.length">
              <v-col class="ml-3 mr-3">
                <div
                  v-for="field in listChamp"
                  :key="field.id"
                  class="block-fields"
                >
                  <v-row>
                    <v-col class="ml-1 mt-2" cols="7">
                      <div class="field-name">
                        {{ field && field.name ? field.name : '' }}
                      </div></v-col
                    >
                    <v-col class="mt-2">
                      <v-switch
                        class="input-checkbox switch-bottom label-switch float-right"
                        :label="
                          $i18n.locale === 'fr'
                            ? 'Champ obligatoire'
                            : 'Required field'
                        "
                        color="#5C2DD3"
                        hide-details=""
                        v-model="field.required"
                        :value="field.required"
                        @change="handleSwitchChange($event, field)"
                      >
                      </v-switch>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        color="#5c2dd3"
                        class="font-sz-14 bold-700  float-right"
                        @click="tryDeleteChampAdd(field)"
                        :title="
                          $i18n.locale == 'fr'
                            ? 'Supprimer un champ'
                            : 'Delete field'
                        "
                        fab
                        small
                        icon
                      >
                        <v-icon>mdi-close-circle-outline</v-icon></v-btn
                      ></v-col
                    >
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTypeEventLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTypeEventError" class="error-msg">
              <ul v-if="Array.isArray(getTypeEventError)">
                <li v-for="(e, index) in getTypeEventError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTypeEventError }}</span>
            </div>
            <div v-if="getCustomFieldsError" class="error-msg">
              <ul v-if="Array.isArray(getCustomFieldsError)">
                <li v-for="(e, index) in getCustomFieldsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCustomFieldsError }}</span>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="addChamps"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addFieldsToTypeEvent')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      initLoading: true,
      listChamp: [],
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      champToDelete: null,
      addFieldsToTypeEvent: false,
      deleteChampModal: false,
      loading: false,
      error: null,
      errorMessages: null
    }
  },

  methods: {
    ...mapActions([
      'fetchOneTypeEvent',
      'resetErrorTypeEvent',

      'addCutemFieldsToTypeEvents',
      'updateCustemFieldTypeEvents',

      'updateOrderCustemFieldsTypeEvents',
      'refreshOrderCustemFieldsTypeEvents',

      'fetchAllCustomFields',
      'updateCustomFields'
    ]),
    handleSwitchChange(newValue, field) {
      field.required = newValue ? 1 : 0
    },
    tryDeleteChampAdd(field) {
      this.listChamp.splice(this.listChamp.indexOf(field), 1)
    },
    async handleClickBtnAddFields() {
      this.addFieldsToTypeEvent = true
      // tabe id type ressource 168
      await this.fetchAllCustomFields(168)
      // Créer une copie du tableau
      this.listChamp = this.getOneTypeEvent.fields.slice()
    },
    handelCancel() {
      this.$router.push('/setting/type-evenements')
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'addFieldsToTypeEvent') {
        this.$refs.addFieldsToTypeEvent.resetValidation()
        this.fetchOneTypeEvent(this.$route.params.id)
      }
      this.resetModal()
    },
    resetModal() {
      this.listChamp = []
      this.loading = false
      this.error = null
      this.resetErrorTypeEvent()
    },
    //add champ
    async addChamps() {
      if (this.$refs.addFieldsToTypeEvent.validate()) {
        let fields = this.listChamp.map(field => ({
          id: field.id,
          required: field.required ? 1 : 0
        }))

        this.loading = true
        const response = await this.addCutemFieldsToTypeEvents({
          idEvent: this.$route.params.id,
          data: { fields: fields }
        })
        if (response) {
          // scroll when add new champ
          this.$nextTick(() => {
            this.$refs.cardSceneCategory.scrollTop = this.$refs.cardSceneCategory.scrollHeight
            this.closeDialog('addFieldsToTypeEvent')
          })
        }
        this.loading = false
      }
    },
    //updated name Champ
    async updateNameChamp(e, champ) {
      const payload = {
        name: e,
        id: champ.id,
        label: champ.name
      }
      await this.updateCustomFields(payload)
      if (this.$route && this.$route.params && this.$route.params.id) {
        this.fetchOneTypeEvent(this.$route.params.id)
      }
    },
    //update champ entet
    updaterequiredChamp(e, champ) {
      this.updateCustemFieldTypeEvents({
        idEvent: this.getOneTypeEvent.id,
        data: { fields: [{ required: e ? 1 : 0, id: champ.id }] }
      })
    },

    //show modal delete Champ
    tryDeleteChamp(champ) {
      this.champToDelete = champ
      this.deleteChampModal = true
    },
    //delete subCategorieChamp
    async deleteChamp() {
      if (
        this.getOneTypeEvent &&
        this.getOneTypeEvent.fields &&
        this.getOneTypeEvent.fields.length
      ) {
        this.getOneTypeEvent.fields = this.getOneTypeEvent.fields.filter(
          field => field.id != this.champToDelete.id
        )

        const payload = this.getOneTypeEvent.fields.map(item => {
          return {
            required: item.required,
            id: item.id
          }
        })
        this.loading = true
        const response = await this.addCutemFieldsToTypeEvents({
          idEvent: this.getOneTypeEvent.id,
          data: { fields: payload }
        })
        if (response) {
          this.closeDialog('deleteChampModal')
        }
        this.loading = false
      }
    },
    onColumnDrop(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let custemFieldsOrder = this.applyDrag(
        this.getOneTypeEvent.fields,
        dropResult
      )
      this.refreshOrderCustemFieldsTypeEvents(custemFieldsOrder)
      this.updateOrderCustemFieldsTypeEvents({
        idEvent: this.getOneTypeEvent.id,
        field_id: this.getOneTypeEvent.fields[addedIndex].id,
        ordre: addedIndex
      })
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    nameWithLang({ name }) {
      return `${name}`
    }
  },
  components: {
    Container,
    Draggable
  },
  computed: {
    ...mapGetters([
      'getTypeEventLoading',
      'getTypeEventError',
      'getOneTypeEvent',
      'getcustomFields',
      'getCustomFieldsLoading',
      'getCustomFieldsError'
    ]),
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getTypeEventError(newValue) {
      this.errorMessages = newValue
    },
    getCustomFieldsError(newValue) {
      this.errorMessages = newValue
    }
  },

  async mounted() {
    this.initLoading = true
    if (this.$route && this.$route.params && this.$route.params.id) {
      await this.fetchOneTypeEvent(this.$route.params.id)
    }
    if (!this.getOneTypeEvent) {
      this.$router.push('/setting/type-evenements')
    }
    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.fields-type-event-setting {
  .block-menu-users {
    .list-tabs {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
    }
    .v-tab {
      &:hover {
        text-decoration: none;
      }
    }
    v-tab .v-tab--active {
      &:hover {
        text-decoration: none;
      }
    }
    .menu-item {
      &.router-link-exact-active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .v-tab {
      text-decoration: none;
      font-weight: 600;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.4px;
      font-family: 'Montserrat', sans-serif;
      &.v-tab--active {
        text-decoration: none;
        font-weight: 700;
        font-size: 12px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
      &.v-tab--disabled {
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
  .list-fildes-type-projet {
    max-height: calc(100vh - 400px) !important;
    height: calc(100vh - 400px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-fildes-type-projet::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .list-fildes-type-projet::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-fildes-type-projet::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .form-actions {
    float: right;
    margin-top: 10px;
  }
  // STYLE BLOCK DRAG AND DROP
  .column-drag-handle,
  .ss-column-drag-handle {
    position: absolute;
    top: 9px;
    left: 6px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ss-column-drag-handle {
    top: 5px;
    left: 3px;
  }

  .input-title-sous-type {
    display: flex;
    justify-content: space-between;
    // margin-left: 25px;
    width: 100%;
    .body-drag {
      display: -webkit-box;
      justify-content: space-between;
      input {
        color: black;
        overflow: visible;
        background-color: transparent;
        border: 0px;
        font-size: 15px;
        text-overflow: ellipsis;
      }
      .custom-select-sm {
        width: 139px;
      }
    }
  }
  .icon-sous-categorie {
    .btn-left {
      color: #495057ab;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      margin-right: 3px;
    }
    .btn-secondary:focus,
    .btn-secondary.focus {
      color: #495057ab;
      background-color: rgba(255, 255, 255, 0.075);
      border-color: rgba(255, 255, 255, 0.075);
    }
  }
  .card-column-header {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 9px;
  }
  .smooth-dnd-container.horizontal {
    display: flex;
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    margin-bottom: 15px;
    border: 2px solid #5c2dd3;
    border-left: 15px solid #5c2dd3;
    background-color: rgb(240, 236, 250) !important;
    border-radius: 4px;
  }
  .smooth-dnd-container {
    margin: 3px;
  }
  .smooth-dnd-container {
    margin: 0px 6px 0px 0px;
    position: relative;
    min-height: 30px;
    min-width: 255px;
  }
  .smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    border: 1px solid #6c757d4d;
    margin-right: 8px;
    background-color: #ebebeb5c;
    border-radius: 4px;
  }
  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 7px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 12px;
    width: 77px;
  }
}
</style>
<style lang="scss">
.fields-type-event-setting {
  .input-title-sous-type {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none !important;
    }
  }
  .card-scene {
    max-height: calc(100vh - 245px) !important;
    height: calc(100vh - 245px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .card-scene::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .card-scene::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .card-scene::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .scroll {
    max-height: calc(100vh - 300px) !important;
    height: calc(100vh - 300px) !important;
  }
}
</style>
